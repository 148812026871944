import React, { useState, useEffect } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Link
} from '@mui/material';
import SelectSmall from '../Data Monitoring/selectFn';
import BasicTextFields from '../Data Monitoring/textfield';


const RepoDefectsView = () => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'rally_id', direction: 'desc' });
  const [selectedProject, setSelectedProject] = useState('');
  const uniqueProjects = Array.from(new Set(tableData.map((row) => row.project_name)));


  const sortedRows = React.useMemo(() => {
    if (sortConfig.key === null) {
      return tableData;
    }
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [tableData, sortConfig]);

  // const handleSort = (key) => {
  //   let direction = 'asc';
  //   if (sortConfig.key === key && sortConfig.direction === 'asc') {
  //     direction = 'desc';
  //   }
  //   setSortConfig({ key, direction });
  // };

  const handleSearchChange = (event) => {
    setSortConfig('rally_id', 'desc');
    setSearchQuery(event.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return sortedRows.filter((row) =>
      (selectedProject === '' || row.project_name === selectedProject) &&
      ((row.defect_creation_result.defect_id && row.defect_creation_result.defect_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.defect_creation_result.defect_name && row.defect_creation_result.defect_name.toLowerCase().includes(searchQuery.toLowerCase())))
    );
  }, [sortedRows, searchQuery, selectedProject]);

  useEffect(() => {
    async function fetchData() {
      // const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
      // if (!accessToken) {  
      //     console.error('No accessToken found in session storage');  
      //     return;  
      // }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fetchUrl = baseUrl + "getDefectReport";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      const data = await response.json();      
      setTableData(data);
    }
    fetchData();
  }, []);
  return (
    <>
      <div style={{ marginTop: '5px', marginLeft: '60px', marginRight: '60px' }}>
        <div style={{ display: "flex", marginLeft: "-0.5%", gap: "3%" }}>
          <SelectSmall projects={uniqueProjects} onProjectChange={setSelectedProject} />
          <BasicTextFields onSearchChange={handleSearchChange} />
        </div>

        <Paper sx={{ width: '100%', overflow: 'auto' }}>
          <TableContainer sx={{ maxHeight: '60%' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Defect Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Defect Rally ID</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Project Mapped</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row) => (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.defect_creation_result.defect_name}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        <Link href={row.defect_creation_result.defect_rally_url} onClick={(event) => { event.stopPropagation(); }} target="_blank" rel="noopener noreferrer">
                          {row.defect_creation_result.defect_id}
                        </Link>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.project_name}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  )
};
export default RepoDefectsView;