import * as React from 'react';
import List from '@mui/material/List';  
import ListItemButton from '@mui/material/ListItemButton';  
import ListItemText from '@mui/material/ListItemText';  
import Collapse from '@mui/material/Collapse';  
import ExpandLess from '@mui/icons-material/ExpandLess';  
import ExpandMore from '@mui/icons-material/ExpandMore';  
import { useUserRole } from '../LoginPage/UserRoleContext';

export default function SelectnoLabel({selectedComponent, onComponentChange }) {

  const [selectedValue, setSelectedValue] = React.useState('instruction');  
  const [open, setOpen] = React.useState(false);  
  const { userRole } = useUserRole();  

  const handleClick = () => {  
    setOpen(!open);  
  };  
  
  const handleSelect = (value) => {  
    setSelectedValue(value);  
    onComponentChange(value);
    setOpen(false);
  };  
  
  const dropdownStyle = {  
    position: 'absolute',  
    top: '100%', 
    left: 0,  
    zIndex: 1 
  };  

  return (  
    <List component="nav" >  
      <ListItemButton onClick={handleClick}>  
        <ListItemText primary="Services" />  
        {open ? <ExpandLess /> : <ExpandMore />}  
      </ListItemButton>  
      <Collapse in={open} timeout="auto" unmountOnExit sx={{...dropdownStyle, border: '1px solid #ddd', borderRadius: '4px', backgroundColor: 'white' }}>  
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'instructions' ? '#ADD8E6' : 'background.paper', '&hover': {
            bgcolor: '#ADD8E6', }}} selected={selectedValue === 'instructions'} onClick={() => handleSelect('instruction')}>
              <ListItemText primary="Main Page" />
          </ListItemButton>  
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'vulnerabilities' ? '#ADD8E6' : 'background.paper', '&:hover': {  
            bgcolor: '#ADD8E6', }}} selected={selectedValue === 'vulnerabilities'} onClick={() => handleSelect('vulnerabilities')}>  
            <ListItemText primary="Vulnerabilities" />  
          </ListItemButton>  
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'pullRequests' ? '#ADD8E6' : 'background.paper','&:hover': {  
            bgcolor: '#ADD8E6'} }} selected={selectedValue === 'pullRequests'} onClick={() => handleSelect('pullRequests')}>   
            <ListItemText primary="Pull Requests" />  
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'orchestrator' ? '#ADD8E6' : 'background.paper','&:hover': {  
            bgcolor: '#ADD8E6'} }} selected={selectedValue === 'orchestrator'} onClick={() => handleSelect('orchestrator')}>   
            <ListItemText primary="Orchestrator" />  
          </ListItemButton>  
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'appRegistrationMonitor' ? '#ADD8E6' : 'background.paper','&:hover': {  
            bgcolor: '#ADD8E6'} }} selected={selectedValue === 'appRegistrationMonitor'} onClick={() => handleSelect('appRegistrationMonitor')}>   
            <ListItemText primary="Azure AppIDs" />
          </ListItemButton> 
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'repoDefectsView' ? '#ADD8E6' : 'background.paper','&:hover': {  
            bgcolor: '#ADD8E6'} }} selected={selectedValue === 'repoDefectsView'} onClick={() => handleSelect('repoDefectsView')}>   
            <ListItemText primary="Defects" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 ,bgcolor: selectedValue === 'qualityResultView' ? '#ADD8E6' : 'background.paper','&:hover': {  
            bgcolor: '#ADD8E6'} }} selected={selectedValue === 'qualityResultView'} onClick={() => handleSelect('qualityResultView')}>   
            <ListItemText primary="QE Results" />
          </ListItemButton>
          {userRole === 'admin' && (  
            <ListItemButton sx={{ pl: 4, bgcolor: selectedValue === 'adminPage' ? '#ADD8E6' : 'background.paper', '&:hover': { bgcolor: '#ADD8E6' } }} selected={selectedValue === 'adminPage'} onClick={() => handleSelect('adminPage')}>  
              <ListItemText primary="Admin Page" />  
            </ListItemButton>  
          )}   
        </List>
      </Collapse>
    </List>
  );
}  