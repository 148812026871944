import React, { useState, useEffect } from 'react';  
import { useDispatch } from 'react-redux';  
import { showAlert } from '../../features/alertSlice';  
import Paper from '@mui/material/Paper';  
import Table from '@mui/material/Table';  
import TableBody from '@mui/material/TableBody';  
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';  
import TableHead from '@mui/material/TableHead';  
import TableRow from '@mui/material/TableRow';  
import IconButton from '@mui/material/IconButton';  
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';  
import Dialog from '@mui/material/Dialog';  
import DialogTitle from '@mui/material/DialogTitle';  
import DialogContent from '@mui/material/DialogContent';  
import DialogActions from '@mui/material/DialogActions';  
import Button from '@mui/material/Button';    
import { Typography,Menu,MenuItem,TextField } from '@mui/material';   
import EditIcon from '@mui/icons-material/Edit';  
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import AddIcon from '@mui/icons-material/Add';
import { Box, TablePagination } from '@mui/material';
import ManageGroups from './ManageGroup';
  
const AdminPage = () => {  
  const [projects, setProjects] = useState([]);  
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);  
  const [editDialogOpen, setEditDialogOpen]=useState(false);
  const [selectedProject, setSelectedProject] = useState(null);  
  const [anchorEl,setAnchorEl]=useState(null);
  const [editDescription, setEditDescription] = useState('');  
  const [addDialogOpen,setAddDialogOpen]=useState(false);
  const [newProjectName,setNewProjectName]=useState('');
  const [newProjectDescription,setNewProjectDescription]=useState('');
  const [page, setPage] = useState(0);  
  const [rowsPerPage] = useState(7); 

  const handleChangePage = (event, newPage) => {  
    setPage(newPage);
  }; 

  const dispatch = useDispatch();    
  
  useEffect(() => {  
    async function fetchProjects() {  
      const accessToken = sessionStorage.getItem('accessToken');  
      if (!accessToken) {  
        console.error('No accessToken found in session storage');  
        return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl + "getAllProjects"; 
      const response = await fetch(fetchUrl, {  
        headers: {  
          'Authorization': `Bearer ${accessToken}`,  
        }  
      });  
      const data = await response.json();  
      setProjects(data);
    }  
    fetchProjects();  
  }, []);  
  
  const handleDeleteDialogOpen = (project) => {  
    setSelectedProject(project);  
    setDeleteDialogOpen(true);  
  };  
  
  const handleDeleteDialogClose = () => {  
    setDeleteDialogOpen(false);  
    handleMenuClose();
  };  
  
  const handleMenuOpen = (event, project) => {  
    setSelectedProject(project);  
    setAnchorEl(event.currentTarget);  
  };  
    
  const handleMenuClose = () => {  
    setAnchorEl(null);  
    setSelectedProject(null);  
  };  

  const handleEditDialogOpen = (project) => {  
    setSelectedProject(project);  
    setEditDescription(project.description);  
    setEditDialogOpen(true);  
  };  
    
  const handleEditDialogClose = () => {  
    setEditDialogOpen(false);   
    handleMenuClose();
  };  
  
  const handleAddDialogOpen=()=>{
    setAddDialogOpen(true);
  }

  const handleAddDialogClose=()=>{
    setAddDialogOpen(false);
  }
  
  const handleAddProject = async () => {  
    try {  
      const accessToken = sessionStorage.getItem('accessToken');  
      if (!accessToken) {  
        console.error('No accessToken found in session storage');  
        return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl + "CreateProject"; // Replace with your add project endpoint  
      const response = await fetch(fetchUrl, {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`,  
        },  
        body: JSON.stringify({ project_name: newProjectName, description: newProjectDescription }),  
      });  
    
      if (response.ok) {  
        const newProject = await response.json();  
        dispatch(showAlert({  
          message: "Project added successfully",  
          severity: "success"  
        }));  
        setProjects([...projects, newProject]);  
      } else if (response.status === 409) {  
        const data = await response.json();  
        dispatch(showAlert({  
          message: `Error: ${data.message}`,  
          severity: "error"  
        }));  
      } else {  
        dispatch(showAlert({  
          message: "Failed to add project",  
          severity: "error"  
        }));  
      }  
    } catch (error) {  
      dispatch(showAlert({  
        message: "There was a problem with the fetch operation",  
        severity: "error"  
      }));  
      console.error('There was a problem with the fetch operation:', error);  
    }  
    
    setNewProjectName('');  
    setNewProjectDescription('');  
    setAddDialogOpen(false);  
  };  
  

  const handleEditProject = async () => {  
    try {  
      const accessToken = sessionStorage.getItem('accessToken');  
      if (!accessToken) {  
        console.error('No accessToken found in session storage');  
        return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl + "editProject"; // Replace with your edit project endpoint  
      const response = await fetch(fetchUrl, {  
        method: 'PATCH',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`,  
        },  
        body: JSON.stringify({ project_id: selectedProject.projectId, description: editDescription }),  
      });  
    
      if (response.ok) {  
        dispatch(showAlert({  
          message: "Project edited successfully",  
          severity: "success"  
        }));  
        setProjects(projects.map(project => project.projectId === selectedProject.projectId ? { ...project, description: editDescription } : project));  
      } else {  
        dispatch(showAlert({  
          message: "Failed to edit project",  
          severity: "error"  
        }));  
      }  
    } catch (error) {  
      dispatch(showAlert({  
        message: "There was a problem with the fetch operation",  
        severity: "error"  
      }));  
      console.error('There was a problem with the fetch operation:', error);  
    }  
    setEditDialogOpen(false);  
    handleMenuClose();
  };  

  const handleDeleteProject = async () => {  
    try {  
      const accessToken = sessionStorage.getItem('accessToken');  
      if (!accessToken) {  
        console.error('No accessToken found in session storage');  
        return;  
      }  
      const baseUrl = process.env.REACT_APP_API_BASE_URL;  
      const fetchUrl = baseUrl + "deleteProject"; // Replace with your delete project endpoint  
      const response = await fetch(fetchUrl, {  
        method: 'DELETE',  
        headers: {  
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${accessToken}`,  
        },  
        body: JSON.stringify({ project_id: selectedProject.projectId }),  
      });  
  
      if (response.ok) {  
        dispatch(showAlert({  
          message: "Project deleted successfully",  
          severity: "success"  
        }));  
        setProjects(projects.filter(project => project.projectId !== selectedProject.projectId));  
      } else if (response.status === 409) {  
        const errorText = await response.text();  
        dispatch(showAlert({  
          message: `Error: ${errorText}`,  
          severity: "error"  
        }));  
      } else {  
        dispatch(showAlert({  
          message: "Failed to delete project",  
          severity: "error"  
        }));  
      }  
    } catch (error) {  
      dispatch(showAlert({  
        message: "There was a problem with the fetch operation",  
        severity: "error"  
      }));  
      console.error('There was a problem with the fetch operation:', error);  
    }  
    setDeleteDialogOpen(false);  
    handleMenuClose();
  };  


  
  return (  
    <div style={{ margin: '20px' ,paddingBottom: '30px'  }}>  
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop:'20px' }}>    
        <Typography variant="h5" sx={{ fontWeight: 'bold',color:'#002677' }}>Admin Tasks</Typography>    
      </div>   
       
      <Paper sx={{ width: 'calc(100% - 30px)', overflow: 'auto' ,marginBottom:'20px',border:'1px solid #ccc',padding: '16px'}}> 
        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: '20px', marginTop:'20px' }}>  
          <Typography variant="h6" sx={{ fontWeight: 'bold',color:'#002677',ml:'6px' }}>Manage Projects</Typography>  
          <Button    
            variant="contained"    
            startIcon={<AddIcon />}    
            onClick={handleAddDialogOpen}    
            sx={{backgroundColor:"#002677",mr:5,borderRadius:999,fontWeight:700}}
          >    
            Add new project    
          </Button>    
        </div> 
        <TableContainer sx={{ maxHeight: '60%' }}>  
          <Table stickyHeader aria-label='sticky table'>  
            <TableHead>  
              <TableRow>  
                <TableCell sx={{ fontWeight: 'bold', fontSize:'16px'}}>Project Name</TableCell>  
                <TableCell sx={{ fontWeight: 'bold', fontSize:'16px' }}>Description</TableCell>  
                <TableCell sx={{ fontWeight: 'bold', fontSize:'16px' }}>Manage</TableCell> 
              </TableRow>  
            </TableHead>  
            <TableBody>  
              {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => ( 
                <TableRow key={index}>  
                  <TableCell>{project.name}</TableCell>  
                  <TableCell>{project.description}</TableCell>  
                  <TableCell>  
                    <IconButton onClick={(event) => handleMenuOpen(event, project)}>  
                        <MoreVertIcon sx={{color:"#00184D"}}/> 
                    </IconButton> 
                    <Menu  
                        anchorEl={anchorEl}  
                        open={Boolean(anchorEl)}  
                        onClose={handleMenuClose}  
                        PaperProps={{  
                            sx: {  
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',   
                            },  
                        }}  
                        >  
                        <MenuItem onClick={() => handleEditDialogOpen(selectedProject)} sx={{ color: '#0C55B8' }}>  
                            <EditIcon style={{ color: '#0C55B8', marginRight: '5px', fontSize: '1rem' }} />  
                            Edit  
                        </MenuItem>  
                        <MenuItem onClick={() => handleDeleteDialogOpen(selectedProject)} sx={{ color: '#0C55B8' }}>  
                            <DeleteOutlineIcon style={{ color: '#0C55B8', marginRight: '5px', fontSize: '1rem' }} />  
                            Delete  
                        </MenuItem>  
                    </Menu>   
                  </TableCell>  
                </TableRow>  
              ))}  
            </TableBody>  
          </Table>
          {/* Pagination */}  
          <Box display="flex" justifyContent="right" alignItems="center" mt={2}>  
            <TablePagination  
              component="div"  
              count={projects.length}  
              rowsPerPage={rowsPerPage}  
              page={page}  
              onPageChange={handleChangePage}  
              labelDisplayedRows={({ count }) => <>  
                Displaying page:{' '}  
                <span style={{ fontWeight: 'bold' }}>  
                  {page + 1} of {Math.ceil(count / rowsPerPage)}  
                </span>  
              </>}  
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}  
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}  
              rowsPerPageOptions={[]}  
              ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (  
                <Box display="flex" alignItems="center">  
                  <Button  
                    onClick={(event) => onPageChange(event, page - 1)}  
                    disabled={page === 0}  
                    sx={{  
                      marginRight: '8px',  
                      marginLeft: '8px',  
                      backgroundColor: '#fff',  
                      borderColor: '#323334',  
                      color: '#323334',  
                      textTransform: 'none',  
                      borderRadius: '50px',  
                      fontWeight: '700',  
                      '&:hover': {  
                        backgroundColor: '#eee',  
                        borderColor: '#000',  
                      },  
                    }}  
                    variant='outlined'  
                  >  
                    Previous  
                  </Button>  
                  <Button  
                    onClick={(event) => onPageChange(event, page + 1)}  
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}  
                    sx={{  
                      color: '#323334',  
                      backgroundColor: '#fff',  
                      borderColor: '#323334',  
                      textTransform: 'none',  
                      borderRadius: '50px',  
                      fontWeight: '700',  
                      '&:hover': {  
                        backgroundColor: '#eee',  
                        borderColor: '#000',  
                      }  
                    }}  
                    variant='outlined'  
                  >  
                    Next  
                  </Button>  
                </Box>  
              )}  
            />  
          </Box>  
          {/* End of Pagination */}  
        </TableContainer>  
      </Paper>  

      <ManageGroups />


      <Paper sx={{ width: 'calc(100% - 30px)', overflow: 'auto' ,marginBottom:'20px',border:'1px solid #ccc',padding: '16px'}}>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: '20px', marginTop:'20px' }}>  
          <Typography variant="h6" sx={{ fontWeight: 'bold',color:'#002677',ml:'6px' }}>Sample Section 1</Typography>  
          <Button    
            variant="contained"    
            startIcon={<AddIcon />}    
            onClick={() => {}}   
            sx={{backgroundColor:"#002677",mr:5,borderRadius:999,fontWeight:700}}
          >    
            Add new Item
          </Button>    
        </div>

      </Paper>

      <Paper sx={{ width: 'calc(100% - 30px)', overflow: 'auto' ,marginBottom:'20px',border:'1px solid #ccc',padding: '16px'}}>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: '20px', marginTop:'20px' }}>  
          <Typography variant="h6" sx={{ fontWeight: 'bold',color:'#002677',ml:'6px' }}>Sample Section 2</Typography>  
          <Button    
            variant="contained"    
            startIcon={<AddIcon />}    
            onClick={() => {}}   
            sx={{backgroundColor:"#002677",mr:5,borderRadius:999,fontWeight:700}}
          >    
            Add new Item 
          </Button>    
        </div>

      </Paper>


      <Dialog    
        open={addDialogOpen}    
        onClose={handleAddDialogClose}    
        aria-labelledby="add-dialog-title"    
        fullWidth    
        maxWidth="sm"    
      >    
        <DialogTitle id="add-dialog-title">Add New Project</DialogTitle>    
        <DialogContent>    
          <TextField    
            margin="dense"    
            label="Project Name"    
            fullWidth    
            value={newProjectName}    
            onChange={(e) => setNewProjectName(e.target.value)}    
          />    
          <TextField    
            margin="dense"    
            label="Description"    
            fullWidth    
            value={newProjectDescription}    
            onChange={(e) => setNewProjectDescription(e.target.value)}    
          />    
        </DialogContent>    
        <DialogActions>    
          <Button onClick={handleAddDialogClose}>Cancel</Button>    
          <Button onClick={handleAddProject} color="primary" disabled={!newProjectName || !newProjectDescription}>Add</Button>    
        </DialogActions>    
      </Dialog>    

      <Dialog  
        open={deleteDialogOpen}  
        onClose={handleDeleteDialogClose}  
        aria-labelledby="delete-dialog-title"  
        fullWidth  
        maxWidth="sm"  
      >  
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>  
        <DialogContent>  
          <Typography variant="body1">  
            Are you sure you want to delete the project "{selectedProject?.name}"?  
          </Typography>  
        </DialogContent>  
        <DialogActions>  
          <Button onClick={handleDeleteDialogClose}>No</Button>  
          <Button onClick={handleDeleteProject} color="primary">Yes</Button>  
        </DialogActions>  
      </Dialog> 

      <Dialog  
        open={editDialogOpen}  
        onClose={handleEditDialogClose}  
        aria-labelledby="edit-dialog-title"  
        fullWidth  
        maxWidth="sm"  
        >  
        <DialogTitle id="edit-dialog-title">Edit Project</DialogTitle>  
        <DialogContent>  
            <TextField  
            margin="dense"  
            label="Project Name"  
            fullWidth  
            value={selectedProject?.name}  
            InputProps={{  
                readOnly: true,  
            }}  
            />  
            <TextField  
            margin="dense"  
            label="Description"  
            fullWidth  
            value={editDescription}  
            onChange={(e) => setEditDescription(e.target.value)}  
            />  
        </DialogContent>  
        <DialogActions>  
            <Button onClick={handleEditDialogClose}>Cancel</Button>  
            <Button onClick={handleEditProject} color="primary">Save</Button>  
        </DialogActions>  
        </Dialog>  
 
    </div>  
  );  
};  
  
export default AdminPage;  