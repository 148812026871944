import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SelectSmall from '../Data Monitoring/selectFn';
import BasicTextFields from '../Data Monitoring/textfield';
import Link from '@mui/material/Link';


const PullRequest = () => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedRow, setExpandedRow] = useState(-1);
  const [sortConfig, setSortConfig] = useState({ key: 'active_prs', direction: 'desc' });
  const [selectedProject, setSelectedProject] = useState('');
  const uniqueProjects = Array.from(new Set(tableData.map((row) => row.project_name)));
  const [currentPrDetails,setCurrentPrDetails]=useState([]);
  const [loadingStates,setLoadingStates]=useState({});

  const sortedRows = React.useMemo(() => {
    if (sortConfig.key === null) {
      return tableData;
    }
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [tableData, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = async(rowIndex) => {
    const repoName = filteredRows[rowIndex].repo_name;  
    setCurrentPrDetails([]);
    if (expandedRow === rowIndex) {
      setExpandedRow(-1);
      setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: false }));
    } else {
      setExpandedRow(rowIndex);
      setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: true }));  
      //Fetch PR details for the clicked repository.  
      try {  
        const accessToken = sessionStorage.getItem('accessToken');  
        if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
        }
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"getSpecificRepoPRs?repo_name="+encodeURIComponent(repoName);
        const response = await fetch(fetchUrl, {  
          headers: {  
              'Authorization': `Bearer ${accessToken}`, 
          }   
        });   
        const data = await response.json();

        if (data && Array.isArray(data.active_prs)) {  
          setCurrentPrDetails(data.active_prs);  
        } else {  
          setCurrentPrDetails([]);
        }
      } catch (error) {  
        console.error("Error fetching PR details:", error);   
      } finally{
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [rowIndex]: false }));
      }

    }

  };

  const calculateDaysPending = (createdAt) => {  
    const createdDate = new Date(createdAt);  
    const currentDate = new Date();  
    const differenceInTime = currentDate.getTime() - createdDate.getTime();  
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));  
    return differenceInDays;  
  };  
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return sortedRows.filter((row) =>
      (selectedProject === '' || row.project_name === selectedProject) &&
      ((row.repo_name && row.repo_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (row.url && row.url.toLowerCase().includes(searchQuery.toLowerCase())))
    );
  }, [sortedRows, searchQuery, selectedProject]);

  function UpDownArrowIconComponent({ direction }) {
    const opacityUp = direction === 'asc' ? 1 : 0.3;
    const opacityDown = direction === 'desc' ? 1 : 0.3;

    return (
      <span style={{ marginLeft: '5px' }}>
        <ArrowUpwardIcon fontSize="10px" style={{ opacity: opacityUp, marginRight: '-2px' }} />
        <ArrowDownwardIcon fontSize="10px" style={{ opacity: opacityDown, marginLeft: '-2px' }} />
      </span>
    );
  }

  useEffect(() => {  
    async function fetchData() {  
        const accessToken = sessionStorage.getItem('accessToken'); // Retrieve groupId from session storage  
        if (!accessToken) {  
            console.error('No accessToken found in session storage');  
            return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl+"getAllRepoPRs";
        const response = await fetch(fetchUrl, {  
            headers: {  
                'Authorization': `Bearer ${accessToken}`, 
            }   
        });  
        const data = await response.json();  
        setTableData(data);  
    }  
    fetchData();  
  }, []);

  useEffect(() => {  
    setExpandedRow(-1);  
  }, [selectedProject]);  

  return (
    <div>
      <div style={{ marginTop: '5px', marginLeft: '60px', marginRight: '60px' }}>
        <div style={{ display: "flex", marginLeft: "-0.5%", gap: "3%" }}>
          <SelectSmall projects={uniqueProjects} onProjectChange={setSelectedProject} />
          <BasicTextFields onSearchChange={handleSearchChange} />
        </div>

        <Paper sx={{ width: '100%', overflow: 'auto' }}>
          <TableContainer sx={{ maxHeight: '60%' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: 'bold' }}>Repository Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Repository Link</TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => handleSort('active_prs')}
                  >
                    Pending PR
                    <UpDownArrowIconComponent
                      direction={sortConfig.key === 'active_prs' ? sortConfig.direction : null}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => (
                  <>
                    <TableRow
                      key={row.sno}
                      onClick={() => handleRowClick(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        {loadingStates[index]?(<LoadingButton  
                          loading  
                          size="small"  
                        >  
                          <ExpandMoreIcon />  
                        </LoadingButton>  
                          ):(<IconButton
                          aria-label="expand row"
                          size="small"
                          sx={{
                            transform: expandedRow === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>)}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.repo_name}</TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        <Link href={row.repo_url} onClick={(event) => {event.stopPropagation();}} target="_blank" rel="noopener noreferrer">
                          {row.repo_url}
                        </Link>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >{row.active_prs}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 ,width: '100%'  }}>
                            <Typography variant="h8" component="h4" fontWeight="bold">
                              Total PR pending: {row.active_prs}
                            </Typography>
                            <Table size="small" aria-label="severity levels table">
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell sx={{ fontWeight: 'bold' }} >PR Name</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }}  >PR Link</TableCell>
                                  <TableCell
                                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                  >
                                    Pending From
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>  
                                {expandedRow === index && currentPrDetails.map((pr, prIndex) => (  
                                  <TableRow key={pr.id}>  
                                    <TableCell />  
                                    <TableCell component="th" scope="row">  
                                      {pr.title}  
                                    </TableCell>  
                                    <TableCell>  
                                      <Link href={pr.url} target="_blank" rel="noopener noreferrer">  
                                        {pr.url}  
                                      </Link>  
                                    </TableCell>  
                                    <TableCell>{calculateDaysPending(pr.createdAt)} days</TableCell>  
                                  </TableRow>  
                                ))}  
                              </TableBody>  
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody >
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default PullRequest;  