import React, { createContext, useState, useContext, useEffect } from 'react';  
import { useMsal } from '@azure/msal-react';  
import { useDispatch } from 'react-redux';
import { showAlert } from '../../features/alertSlice';
// Create a context for the user role  
const UserRoleContext = createContext({});  
  
// Hook to access the user role context  
export const useUserRole = () => useContext(UserRoleContext);  
  
// Provider component that fetches the user role and provides it to its children  
export const UserRoleProvider = ({ children }) => {  
    const { instance, accounts } = useMsal();  
    const [userRole, setUserRole] = useState(null);
    const [userName,setUserName]=useState("user-name");
    const [userEmail,setUserEmail]=useState("");
    const [isLoading, setIsLoading] = useState(true); // Add loading state  
    const dispatch=useDispatch(); 
  
    useEffect(() => {  
        const getUserRole = async () => {  
            setIsLoading(true); // Start loading 
            try {  
                const request = {  
                    scopes: ['user.read'], 
                    account: accounts[0], 
                };  
                const tokenResponse = await instance.acquireTokenSilent(request);
                const baseUrl = process.env.REACT_APP_API_BASE_URL;  
                const fetchUrl = baseUrl+"getUserDetails";
                const response = await fetch(fetchUrl, { 
                    method: 'POST',
                    headers: {  
                        'Authorization': `Bearer ${tokenResponse.accessToken}`,
                        'uniqueId':tokenResponse.uniqueId
                    } ,
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserRole(data.role); // Set user role in state
                    setUserName(data.username);
                    setUserEmail(data.email || 'incubator_srvc@optum.com');
                    sessionStorage.setItem('accessToken',tokenResponse.accessToken);
                }else if(response.status===401){
                    console.log("unauthorized return:401");
                    dispatch(showAlert({
                        message:"unauthorized user:401",
                        severity:"error"
                      }));
                } else {
                    // Handle HTTP errors
                    console.error(`API returned an error: ${response.status}`);
                }
            } catch (error) {
                // Handle errors in acquiring token or making API call
                console.error(error);
            }
            setIsLoading(false);
        };

        if (accounts && accounts.length > 0) {
            getUserRole();
        }  else{
            setIsLoading(false);
        }
    }, [accounts, instance,dispatch]);

    return (
        <UserRoleContext.Provider value={{userRole,isLoading,userName,userEmail}}>
            {children}
        </UserRoleContext.Provider>
    );
};