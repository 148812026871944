import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';  
import { showAlert } from '../../features/alertSlice';    
import Paper from '@mui/material/Paper';  
import Table from '@mui/material/Table';  
import TableBody from '@mui/material/TableBody';  
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';  
import TableHead from '@mui/material/TableHead';  
import TableRow from '@mui/material/TableRow';  
import { Typography, Box, TablePagination, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Menu, MenuItem, Select, InputLabel, FormControl } from '@mui/material';  
import EditIcon from '@mui/icons-material/Edit';  
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';  
import MoreVertIcon from '@mui/icons-material/MoreVert';  
import AddIcon from '@mui/icons-material/Add';  
    
const ManageGroups = () => {  
  const [groups, setGroups] = useState([]);  
  const [page, setPage] = useState(0);  
  const [rowsPerPage] = useState(4);  
  const [anchorEl, setAnchorEl] = useState(null);  
  const [selectedGroup, setSelectedGroup] = useState(null);  
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);  
  const dispatch = useDispatch();  
  //add
  const [addDialogOpen,setAddDialogOpen]=useState(false);
  const [newGroupName,setNewGroupName]=useState('');
  const [newGroupId, setNewGroupId] = useState('');  
  const [newGroupRole, setNewGroupRole] = useState('');  
  const [newGithubToken, setNewGithubToken] = useState('');  
  const [newProject, setNewProject] = useState('');  
  const [projects, setProjects] = useState([]); 
  
  //edit
  const [editDialogOpen, setEditDialogOpen] = useState(false);  
  const [editProjects, setEditProjects] = useState([]);  
  const [editNewProject, setEditNewProject] = useState('');  
  const [editProjectToDelete, setEditProjectToDelete] = useState('');  
  const [editProjectsToDelete, setEditProjectsToDelete] = useState([]); 
  

  useEffect(() => {  
    async function fetchGroups() {  
        const accessToken = sessionStorage.getItem('accessToken');  
        if (!accessToken) {  
            console.error('No accessToken found in session storage');  
            return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl + "getAllGroups"; // Replace with your actual endpoint  
        const response = await fetch(fetchUrl, {  
            headers: {  
                'Authorization': `Bearer ${accessToken}`,  
            }  
        });  
        const data = await response.json();  
        setGroups(data);  
    }  
    fetchGroups();  
    }, []);  

  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
  
  const handleMenuOpen = (event, group) => {  
    setSelectedGroup(group);  
    setAnchorEl(event.currentTarget);  
  };  
  
  const handleMenuClose = () => {  
    setAnchorEl(null);  
    setSelectedGroup(null);  
  };  
  
  const handleEditDialogOpen = (group) => {  
    setSelectedGroup(group);  
    setEditDialogOpen(true);  
  };  
  
  const handleEditDialogClose = () => {  
    setEditDialogOpen(false);  
    handleMenuClose();  
  };  
  
  const handleDeleteDialogOpen = (group) => {  
    setSelectedGroup(group);  
    setDeleteDialogOpen(true);  
  };  
  
  const handleDeleteDialogClose = () => {  
    setDeleteDialogOpen(false);  
    handleMenuClose();  
  };  
  
  const handleAddDialogOpen = () => {  
    setAddDialogOpen(true);  
  };  
  
  const handleAddDialogClose = () => {  
    setAddDialogOpen(false);  
  }; 

  const handleAddProject=()=>{
    if(newProject){
        setProjects([...projects,newProject]);
        setNewProject('');
    }
  };

  const handleRemoveProject = (index) => {  
    const updatedProjects = projects.filter((_, i) => i !== index);  
    setProjects(updatedProjects);  
  };


  const handleEditAddProject = () => {  
    if (editNewProject) {  
        setEditProjects([...editProjects, editNewProject]);  
        setEditNewProject('');  
    }  
};  
  
const handleEditRemoveProject = (index) => {  
    const updatedProjects = editProjects.filter((_, i) => i !== index);  
    setEditProjects(updatedProjects);  
};  
  
const handleEditAddProjectToDelete = () => {  
    if (editProjectToDelete) {  
        setEditProjectsToDelete([...editProjectsToDelete, editProjectToDelete]);  
        setEditProjectToDelete('');  
    }  
};  
  
const handleEditRemoveProjectToDelete = (index) => {  
    const updatedProjects = editProjectsToDelete.filter((_, i) => i !== index);  
    setEditProjectsToDelete(updatedProjects);  
}; 

  const handleAddGroup = async() => {  
    const newGroup = {  
      group_id:newGroupId,
      group_name: newGroupName,  
      role: newGroupRole,  
      projects: projects,  
      github_token: newGithubToken  
    };  
    try {  
        const accessToken = sessionStorage.getItem('accessToken');  
        if (!accessToken) {  
          console.error('No accessToken found in session storage');  
          return;  
        }  
        const baseUrl = process.env.REACT_APP_API_BASE_URL;  
        const fetchUrl = baseUrl + "addGroup"; 
    
        const response = await fetch(fetchUrl, {  
          method: 'POST',  
          headers: {  
            'Content-Type': 'application/json',  
            'Authorization': `Bearer ${accessToken}`,  
          },  
          body: JSON.stringify(newGroup),  
        });  
    
        if (response.ok) {  
          let createdGroup = await response.json();    
            createdGroup = {  
            group_id: createdGroup.group.group_id || '',  
            group_name: createdGroup.group.group_name || '',  
            role: createdGroup.group.role || '',  
            projects: createdGroup.group.projects || [],  
            github_token: createdGroup.group.github_token || ''  
          };   
          dispatch(showAlert({  
            message: "Group added successfully",  
            severity: "success"  
          }));  
          setGroups([...groups, createdGroup]);   
          setNewGroupId('');  
          setNewGroupName('');  
          setNewGroupRole('');  
          setNewGithubToken('');  
          setProjects([]); 
        } else if (response.status === 409) {  
            const data = await response.json();  
            dispatch(showAlert({  
              message: `Error: ${data.message}`,  
              severity: "error"  
            }));  
        } else {  
          const errorData = await response.json();  
          console.error('Failed to add group:', errorData);  
        }  
      } catch (error) {  
        console.error('There was a problem with the fetch operation:', error);  
      }
      handleAddDialogClose();  
    }; 

  
    const handleEditGroup = async () => {  
        const updatedGroup = {  
            group_name: selectedGroup.group_name,  
            add_projects: editProjects,  
            delete_projects: editProjectsToDelete  
        };  
    
        try {  
            const accessToken = sessionStorage.getItem('accessToken');  
            if (!accessToken) {  
                console.error('No accessToken found in session storage');  
                return;  
            }  
            const baseUrl = process.env.REACT_APP_API_BASE_URL;  
            const fetchUrl = baseUrl + "editGroup"; 
    
            const response = await fetch(fetchUrl, {  
                method: 'PATCH',  
                headers: {  
                    'Content-Type': 'application/json',  
                    'Authorization': `Bearer ${accessToken}`,  
                },  
                body: JSON.stringify(updatedGroup),  
            });  
    
            if (response.ok) {   
                const updatedGroupResponse = await response.json();  
                const updatedGroups = groups.map(group =>   
                    group.group_name === selectedGroup.group_name ? {...group,projects: updatedGroupResponse.projects} : group  
                ); 

                dispatch(showAlert({  
                    message: "Group updated successfully",  
                    severity: "success"  
                })); 
                setGroups(updatedGroups);

                // Reset input fields  
                setEditProjects([]);  
                setEditNewProject('');  
                setEditProjectsToDelete([]);  
                setEditProjectToDelete(''); 
            }  else if (response.status === 400) {  
                const errorText = await response.json();  
                dispatch(showAlert({  
                  message: `Error: ${errorText}`,  
                  severity: "error"  
                }));  
            } else {  
                const errorData = await response.json();  
                console.error('Failed to edit group:', errorData);  
            }  
        } catch (error) {  
            console.error('There was a problem with the fetch operation:', error);  
        }  
        handleEditDialogClose();
    };


    const handleDeleteGroup = async () => {  
        try {  
            const accessToken = sessionStorage.getItem('accessToken');  
            if (!accessToken) {  
                console.error('No accessToken found in session storage');  
                return;  
            }  
            const baseUrl = process.env.REACT_APP_API_BASE_URL;  
            const fetchUrl = baseUrl + "deleteGroup";
      
            const response = await fetch(fetchUrl, {  
                method: 'DELETE',  
                headers: {  
                    'Content-Type': 'application/json',  
                    'Authorization': `Bearer ${accessToken}`,  
                },  
                body: JSON.stringify({ group_name: selectedGroup.group_name }),  
            });  
      
            if (response.ok) {  
                const updatedGroups = groups.filter(group => group.group_name !== selectedGroup.group_name);  
                setGroups(updatedGroups);  
                dispatch(showAlert({  
                    message: "Group deleted successfully",  
                    severity: "success"  
                  }));     
            } else if (response.status === 400) {  
                const errorText = await response.json();  
                dispatch(showAlert({  
                  message: `Error: ${errorText}`,  
                  severity: "error"  
                }));  
            } else if (response.status === 409) {  
                const errorText = await response.text(); 
                dispatch(showAlert({  
                  message: `Error: ${errorText}`,  
                  severity: "error"  
                }));  
            } else {  
                const errorData = await response.json();  
                console.error('Failed to delete group:', errorData);  
            }  
        } catch (error) {  
            console.error('There was a problem with the fetch operation:', error);  
        }  
        handleDeleteDialogClose();
    };   
  
  return (  
    <div>
        <Paper sx={{ width: 'calc(100% - 30px)', overflow: 'auto' ,marginBottom:'20px',border:'1px solid #ccc',padding: '16px'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>  
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#002677' }}>Manage Groups</Typography>  
                <Button  
                variant="contained"  
                startIcon={<AddIcon />}  
                onClick={handleAddDialogOpen}  
                sx={{ backgroundColor: "#002677", mr: 5, borderRadius: 999, fontWeight: 700 }}  
                >  
                Add new Group  
                </Button>  
            </div>  
        
            <TableContainer sx={{ maxHeight: '60%' }}>  
            <Table stickyHeader aria-label='sticky table'>  
                <TableHead>  
                <TableRow>  
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>Group Name</TableCell>  
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>Role</TableCell>  
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>Projects</TableCell>  
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '16px' }}>Manage</TableCell>  
                </TableRow>  
                </TableHead>  
                <TableBody>  
                {groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((group, index) => (  
                    <TableRow key={index}>  
                    <TableCell>{group.group_name}</TableCell>  
                    <TableCell>{group.role}</TableCell>  
                    <TableCell>{Array.isArray(group.projects) ? group.projects.join(', ') : ''}</TableCell>  
                    <TableCell>  
                        <IconButton onClick={(event) => handleMenuOpen(event, group)}>  
                        <MoreVertIcon sx={{ color: "#00184D" }} />  
                        </IconButton>  
                        <Menu  
                        anchorEl={anchorEl}  
                        open={Boolean(anchorEl)}  
                        onClose={handleMenuClose}  
                        PaperProps={{  
                            sx: {  
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',  
                            },  
                        }}  
                        >  
                        <MenuItem onClick={() => handleEditDialogOpen(selectedGroup)} sx={{ color: '#0C55B8' }}>  
                            <EditIcon style={{ color: '#0C55B8', marginRight: '5px', fontSize: '1rem' }} />  
                            Edit  
                        </MenuItem>  
                        <MenuItem onClick={() => handleDeleteDialogOpen(selectedGroup)} sx={{ color: '#0C55B8' }}>  
                            <DeleteOutlineIcon style={{ color: '#0C55B8', marginRight: '5px', fontSize: '1rem' }} />  
                            Delete  
                        </MenuItem>  
                        </Menu>  
                    </TableCell>  
                    </TableRow>  
                ))}  
                </TableBody>  
            </Table>  
            {/* Pagination */}  
            <Box display="flex" justifyContent="right" alignItems="center" mt={2}>  
                <TablePagination  
                component="div"  
                count={groups.length}  
                rowsPerPage={rowsPerPage}  
                page={page}  
                onPageChange={handleChangePage}  
                labelDisplayedRows={({ count }) => <>  
                    Displaying page:{' '}  
                    <span style={{ fontWeight: 'bold' }}>  
                    {page + 1} of {Math.ceil(count / rowsPerPage)}  
                    </span>  
                </>}  
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}  
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}  
                rowsPerPageOptions={[]}  
                ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (  
                    <Box display="flex" alignItems="center">  
                    <Button  
                        onClick={(event) => onPageChange(event, page - 1)}  
                        disabled={page === 0}  
                        sx={{  
                        marginRight: '8px',  
                        marginLeft: '8px',  
                        backgroundColor: '#fff',  
                        borderColor: '#323334',  
                        color: '#323334',  
                        textTransform: 'none',  
                        borderRadius: '50px',  
                        fontWeight: '700',  
                        '&:hover': {  
                            backgroundColor: '#eee',  
                            borderColor: '#000',  
                        },  
                        }}  
                        variant='outlined'  
                    >  
                        Previous  
                    </Button>  
                    <Button  
                        onClick={(event) => onPageChange(event, page + 1)}  
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}  
                        sx={{  
                        color: '#323334',  
                        backgroundColor: '#fff',  
                        borderColor: '#323334',  
                        textTransform: 'none',  
                        borderRadius: '50px',  
                        fontWeight: '700',  
                        '&:hover': {  
                            backgroundColor: '#eee',  
                            borderColor: '#000',  
                        }  
                        }}  
                        variant='outlined'  
                    >  
                        Next  
                    </Button>  
                    </Box>  
                )}  
                />  
            </Box>  
            {/* End of Pagination */}  
            </TableContainer>   
        </Paper>

        <Dialog  
            open={addDialogOpen}  
            onClose={handleAddDialogClose}  
            aria-labelledby="add-dialog-title"  
            fullWidth  
            maxWidth="sm"  
        >  
            <DialogTitle id="add-dialog-title">Add New Group</DialogTitle>  
            <DialogContent>  
            <TextField  
                margin="dense"  
                label="Group Id"  
                fullWidth  
                value={newGroupId}  
                onChange={(e) => setNewGroupId(e.target.value)}  
            />  
            <TextField  
                margin="dense"  
                label="Group Name"  
                fullWidth  
                value={newGroupName}  
                onChange={(e) => setNewGroupName(e.target.value)}  
            />  
            <FormControl fullWidth margin="dense">  
                <InputLabel>Role</InputLabel>  
                <Select  
                value={newGroupRole}  
                onChange={(e) => setNewGroupRole(e.target.value)}  
                >  
                <MenuItem value={"admin"}>Admin</MenuItem>  
                <MenuItem value={"non-admin"}>Non-Admin</MenuItem>  
                </Select>  
            </FormControl>  
            <TextField  
                margin="dense"  
                label="Github Token"  
                fullWidth  
                value={newGithubToken}  
                onChange={(e) => setNewGithubToken(e.target.value)}  
            />  
            <TextField  
                margin="dense"  
                label="Add Project"  
                fullWidth  
                value={newProject}  
                onChange={(e) => setNewProject(e.target.value)}  
            />  
            <Button onClick={handleAddProject} color="primary" >Add Project</Button>  
            <Box mb={2} mx={2}>  
                {projects.map((project, index) => (  
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mt={1}>  
                        <Typography>{project}</Typography> 
                        <Button onClick={()=>(handleRemoveProject(index))} color="secondary" variant="contained" size="small" sx={{ ml: 2 }}>Remove</Button> 
                    </Box>
                ))}  
            </Box>  
            </DialogContent>  
            <DialogActions>  
            <Button onClick={handleAddDialogClose}>Cancel</Button>  
            <Button onClick={handleAddGroup} color="primary" disabled={!newGroupId || !newGroupName || !newGroupRole || !newGithubToken}>Add</Button>  
            </DialogActions>  
        </Dialog>  
    
        {/* Edit Dialog */}  
        <Dialog  
            open={editDialogOpen}  
            onClose={handleEditDialogClose}  
            aria-labelledby="edit-dialog-title"  
            fullWidth  
            maxWidth="sm"  
        >  
            <DialogTitle id="edit-dialog-title">Edit Group</DialogTitle>  
            <DialogContent>  
            <TextField  
                margin="dense"  
                label="Group Name"  
                fullWidth  
                value={selectedGroup?.group_name}  
                InputProps={{  
                readOnly: true,  
                }}  
            />  
            <TextField  
                margin="dense"  
                label="Add Project"  
                fullWidth  
                value={editNewProject}  
                onChange={(e) => setEditNewProject(e.target.value)} 
            />  
            <Button onClick={handleEditAddProject} color="primary" >Add Project</Button>  
            <Box mb={3} mx={2}>  
                {editProjects.map((project, index) => (  
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mt={1}>  
                        <Typography>{project}</Typography>  
                        <Button onClick={() => handleEditRemoveProject(index)} color="secondary" variant="contained" size="small" sx={{ ml: 2 }}>  
                            Remove  
                        </Button>  
                    </Box>  
                ))}  
            </Box> 
            <TextField  
                margin="dense"  
                label="Delete Project"  
                fullWidth  
                value={editProjectToDelete}  
                onChange={(e) => setEditProjectToDelete(e.target.value)}  
            />  
            <Button onClick={handleEditAddProjectToDelete} color="primary">Add to Delete List</Button>  
            <Box mb={2} mx={2}>  
                {editProjectsToDelete.map((project, index) => (  
                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mt={1}>  
                        <Typography>{project}</Typography>  
                        <Button onClick={() => handleEditRemoveProjectToDelete(index)} color="secondary" variant="contained" size="small" sx={{ ml: 2 }}>  
                            Remove  
                        </Button>  
                    </Box>  
                ))}  
            </Box> 
            </DialogContent>  
            <DialogActions>  
            <Button onClick={handleEditDialogClose}>Cancel</Button>  
            <Button onClick={handleEditGroup} color="primary">Save</Button>  
            </DialogActions>  
        </Dialog>  
    
        {/* Delete Dialog */}  
        <Dialog  
            open={deleteDialogOpen}  
            onClose={handleDeleteDialogClose}  
            aria-labelledby="delete-dialog-title"  
            fullWidth  
            maxWidth="sm"  
        >  
            <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>  
            <DialogContent>  
            <Typography variant="body1">  
                Are you sure you want to delete the group "{selectedGroup?.group_name}"?  
            </Typography>  
            </DialogContent>  
            <DialogActions>  
            <Button onClick={handleDeleteDialogClose}>No</Button>  
            <Button onClick={handleDeleteGroup} color="primary">Yes</Button>  
            </DialogActions>  
        </Dialog>  
    </div>  
  );  
};  
  
export default ManageGroups;  
