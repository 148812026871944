import * as React from 'react';
import { useState,useEffect } from 'react';
import {
  Box, Container, Typography, Button, Dialog, AppBar, Toolbar, IconButton, List, ListItemButton, ListItemText, Divider, Slide
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CloseIcon from '@mui/icons-material/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QualtyResultView = () => {  
  const [assetPathData, setAssetPathData] = useState({});
  const [open, setOpen] = React.useState(false);


  // const randomStringFileName = () => {
  //   return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 3);
  // }

  const dowlaodReportAsset = async (assetPath, fileName) => {
    const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
    const fetchUrl = baseUrl + "QualityResultAssetContentFetchService";
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
      },
      body: JSON.stringify({ blobPath: assetPath }),
    });
    if (response && response.status === 200) {
      // const blob = await response.blob();
      // const blobURL = URL.createObjectURL(blob);
      return "Download Success";
    } else {
      return "Download Failed";
    }
  };

  // const deleteReportAsset = async (assetPath) => {    
  // }

  const handleReportViewerModalOpen = () => {
    var fileName = "10302024143338"; //+ "_" + randomStringFileName() + ".zip";
    dowlaodReportAsset("Testresults/BCA/BCA-TestAutomation-UI-Test/Reports/10302024143338", fileName).then((result) =>
      console.log(result)
    )
    setOpen(true);
  };

  const handleReportViewerModalClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    async function fetchData() {
      const baseUrl = process.env.REACT_APP_APPREG_API_BASE_URL;
      const fetchUrl = baseUrl + "QualityResultAssetPathsFetchService";
      const response = await fetch(fetchUrl, {
        // headers: {  
        //     'Authorization': `Bearer ${accessToken}`, 
        // }   
      });
      if (response && response.status === 200) {
        const responseData = await response.json();
        setAssetPathData(responseData)
      }
    }
    fetchData();
  }, []);
  
  return (    
    <>    
      <Box display="flex" flexDirection="column" minHeight="100vh" >    
        <Box bgcolor="#EEF4FF" pt={6} pb={3} px={10} sx={{margin:0}}>  
          <Container maxWidth={false}>            
            <Typography   gutterBottom sx={{color:'#00184D'}} > 
              Quality Test Results Blobs / Stored Reports
            </Typography>            
          </Container>  
        </Box>
        <Box px={10} pb={10} sx={{margin:0}}>
          <Container maxWidth={false}>  
            {Object.keys(assetPathData).map((item, index) => (  
              <Box p={2} key={index}>  
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Container : {assetPathData[item].StorageContainerName}
                </Typography>
                <Typography mt="32px" mb="0px" fontWeight={700} fontSize="20px" gutterBottom sx={{ color: '#00184D' }}>  
                  Folder : {assetPathData[item].StorageFolderName}
                </Typography>  
                Reports                
                <ul>  
                  {assetPathData[item].StorageFilePaths.map((item, idx) => (
                    // <li key={idx}><a href={item.AssetIndexPath} rel='noreferrer' target='_blank'>{item.AssetDisplayName}</a></li>
                    // AssetBlobDirectoryPath
                    <li>
                      <Button variant="outlined" onClick={handleReportViewerModalOpen} startIcon={<SummarizeIcon />}>
                        {item.AssetDisplayName}
                      </Button>
                    </li>
                  ))}  
                </ul>  
              </Box>  
            ))}  
          </Container>  
        </Box>
      </Box>

      {/* Quality Result Viewer Modal Window */}
      <Dialog fullScreen open={open} onClose={handleReportViewerModalClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleReportViewerModalClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
            <Button autoFocus color="inherit" onClick={handleReportViewerModalClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItemButton>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItemButton>
          <Divider />
          <ListItemButton>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItemButton>
        </List>
      </Dialog>
    </>  
  );  
}; 

export default QualtyResultView;  